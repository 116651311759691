(function() {
    'use strict';

    angular.module('ionicNotes', [
        'ion-datetime-picker',
        'ionic',
        'ionicAlert',
        'ionicConfirmation',
        'ionicData',
        'ionicDataTable',
        'ionicFilter',
        'ionicFriend',
        'ionicLogin',
        'ionicNoteCategories',
        'uabDefaultVariable',
        'uabEnvironment',
        'uabMoment',
        'uabRedirect'
    ]);
})();
(function() {
    'use strict';

    angular.module('ionicNotes').service(
        'NoteCreateService',
        NoteCreateService
    );

    NoteCreateService.$inject = [
        'DefaultVariableService',
        'ProjectService',
        'RequestManagementService',
        'NoteListService'
    ];

    function NoteCreateService(
        DefaultVariableService,
        ProjectService,
        RequestManagementService,
        NoteListService
    ) {
        var NoteCreateService = this;

        NoteCreateService.createNote = createNote;
        function createNote(newNote) {
            var options = RequestManagementService.getRequest();

            options = RequestManagementService.setModel(options, 'notes');
            options = RequestManagementService.setAction(options, 'add');

            options = RequestManagementService.setData(
                options,
                newNote
            );

            return ListManager.create(options);
        }

        NoteCreateService.reset = reset;
        function reset() {

        }

        var ListManager = NoteListService.getListManager();

        NoteCreateService.reset();

        return NoteCreateService;
    }
})();
(function() {
    'use strict';

    angular.module('ionicNotes').service(
        'NoteEditService',
        NoteEditService
    );

    NoteEditService.$inject = [
        'DataManagementService',
        'DefaultVariableService',
        'LocalStorageService',
        'LoginService',
        'RequestManagementService'
    ];

    function NoteEditService(
        DataManagementService,
        DefaultVariableService,
        LocalStorageService,
        LoginService,
        RequestManagementService
    ) {
        var NoteEditService = this;

        NoteEditService.deleteNote = deleteNote;
        function deleteNote(note) {
            var options = RequestManagementService.getRequest();

            options = RequestManagementService.setModel(options, 'notes');
            options = RequestManagementService.setAction(options, 'delete', note.id);

            return DataManagementService.request(options);
        }

        NoteEditService.editNote = editNote;
        function editNote(note) {
            var options = RequestManagementService.getRequest();

            options = RequestManagementService.setModel(options, 'notes');
            options = RequestManagementService.setAction(options, 'edit', note.id);

            options = RequestManagementService.setData(options, note);

            return DataManagementService.request(options).then(
                function(data) {
                    if (data) {
                        return DefaultVariableService.getObject(
                            data.notes
                        );
                    }

                    return false;
                }
            );
        }

        NoteEditService.reset = reset;
        function reset() {

        }

        NoteEditService.reset();

        return NoteEditService;
    }
})();
(function() {
    'use strict';

    angular.module('ionicNotes').service('NoteListService', NoteListService);

    NoteListService.$inject = [
        'DefaultVariableService',
        'ListManagementService',
        'ProjectService',
        'RequestManagementService'
    ];

    function NoteListService(
        DefaultVariableService,
        ListManagementService,
        ProjectService,
        RequestManagementService
    ) {
        var NoteListService = this;

        NoteListService.getListManager = getListManager;
        function getListManager() {
            return ListManager;
        }

        NoteListService.loadNotes = loadNotes;
        function loadNotes(options) {
            return ProjectService.getProject().then(
                function(project) {
                    if (project) {
                        options = RequestManagementService.getRequest(options);

                        options = RequestManagementService.setModel(options, 'notes');
                        options = RequestManagementService.setAction(options, 'get');

                        options = RequestManagementService.setParams(
                            options,
                            [
                                'NoteCategories',
                                'Participants'
                            ]
                        );

                        return ListManager.getPage(options);
                    }

                    return false;
                }
            );
        }

        NoteListService.reset = reset;
        function reset() {
            ListManager.reset();
        }

        var ListManager = ListManagementService.getListManager(
            NoteListService.loadNotes
        );

        NoteListService.reset();

        return NoteListService;
    }
})();
(function() {
    'use strict';

    angular.module('ionicNotes').controller(
        'NoteCreateController',
        NoteCreateController
    );

    NoteCreateController.$inject = [
        '$scope',
        'AlertService',
        'DefaultVariableService',
        'MomentService',
        'NoteCreateService'
    ];

    function NoteCreateController(
        $scope,
        AlertService,
        DefaultVariableService,
        MomentService,
        NoteCreateService
    ) {
        var NoteCreateController = this;

        NoteCreateController.moment = MomentService;

        $scope.$watch(
            'onCancel',
            function(onCancel) {
                NoteCreateController.onCancel = DefaultVariableService.get(
                    onCancel,
                    false
                );
            }.bind(NoteCreateController)
        );

        $scope.$watch(
            'onCreate',
            function(onCreate) {
                NoteCreateController.onCreate = DefaultVariableService.getPromise(
                    onCreate
                );
            }.bind(NoteCreateController)
        );

        NoteCreateController.cancel = cancel;
        function cancel() {
            NoteCreateController.onCancel();
        }

        NoteCreateController.createNote = createNote;
        function createNote(newNote) {
            newNote = DefaultVariableService.get(
                newNote,
                NoteCreateController.newNote
            );

            NoteCreateController.isCreatingNote = true;

            return NoteCreateService.createNote(newNote).then(
                function(newNote) {
                    if (newNote) {
                        NoteCreateController.resetnewNote();

                        NoteCreateController.onCreate(newNote);

                        AlertService.addMessage('NOTE CREATED.');
                    }

                    return newNote;
                }
            ).finally(
                function() {
                    NoteCreateController.isCreatingNote = false;
                }
            );
        }

        NoteCreateController.reset = reset;
        function reset() {
            NoteCreateController.resetNewNote();

            NoteCreateController.showCreator = false;
        }

        NoteCreateController.resetNewNote = resetNewNote;
        function resetNewNote() {
            NoteCreateController.newNote = {};
            NoteCreateController.newNote.participant = DefaultVariableService.getObject();
        }

        NoteCreateController.selectCategory = selectCategory;
        function selectCategory(category) {
            NoteCreateController.newNote.note_category = DefaultVariableService.getObject(
                category
            );

            NoteCreateController.newNote.note_category_id = NoteCreateController.newNote.note_category.id;
        }

        NoteCreateController.selectParticipant = selectParticipant;
        function selectParticipant(participant) {
            NoteCreateController.newNote.participants = DefaultVariableService.getObject(
                participant
            );

            NoteCreateController.newNote.participant_id = NoteCreateController.newNote.participant.id;
        }

        NoteCreateController.toggleCreator = toggleCreator;
        function toggleCreator() {
           NoteCreateController.onCancel();
        }

        NoteCreateController.init = init;
        function init() {
            NoteCreateController.reset();
        }

        NoteCreateController.init();
    }
})();
(function() {
    'use strict';

    angular.module('ionicNotes').directive(
        'ionicNoteCreate',
        ionicNoteCreate
    );

    function ionicNoteCreate() {
        return {
            controller:   'NoteCreateController',
            controllerAs: 'ctrl',
            restrict:     'E',
            scope: {
                onCreate:              '=',
                onCancel:              '='
            },
            template:'<ion-list class="list padding-horizontal"><div class="row"><label class="item item-input item-floating-label col-12"><span class="input-label">TITLE</span> <input type="text" placeholder="TITLE" data-ng-model="ctrl.newNote.title"></label></div><label class="item item-input item-stacked-label row"><span class="input-label">CATEGORY</span><ionic-note-category-filter on-select="ctrl.selectCategory" target="ctrl.newNote.note_category"></ionic-note-category-filter></label> <label class="item item-input item-stacked-label row"><span class="input-label">PARTICIPANT</span><ionic-friend-filter on-select="ctrl.selectParticipant" target="ctrl.newNote.participants"></ionic-friend-filter></label> <label class="item item-input"><textarea data-ng-model="ctrl.newNote.note" placeholder="MESSAGE">\n            {{ ctrl.newNote.note }}\n        </textarea></label><div class="row"><button class="button button-full accept-button" data-ng-class="{ \'col-xl-6\': ctrl.onCancel }" data-ng-click="ctrl.createNote()" data-ng-disabled="ctrl.isCreatingNote">{{ ctrl.isCreatingNote ? \'CREATING...\' : \'CREATE\' }}</button> <button data-ng-if="ctrl.onCancel" class="button button-full decline-button" data-ng-class="{ \'col-xl-6\': ctrl.onCancel }" data-ng-click="ctrl.cancel()" data-ng-disabled="ctrl.isCreatingNote">CANCEL</button></div></ion-list>'
        };
    }
})();
(function() {
    'use strict';

    angular.module('ionicNotes').controller(
        'NoteManagementController',
        NoteManagementController
    );

    NoteManagementController.$inject = [
        'DefaultVariableService',
        'EnvironmentService',
        'LoginService',
        'RedirectService',
        '$scope',
        'NoteListService'
    ];

    function NoteManagementController(
        DefaultVariableService,
        EnvironmentService,
        LoginService,
        RedirectService,
        $scope,
        NoteListService
    ) {
        var NoteManagementController = this;

        $scope.$watch(
            'allowCreate',
            function(allowCreate) {
                NoteManagementController.allowCreate = DefaultVariableService.get(
                    allowCreate,
                    true
                );
            }.bind(NoteManagementController)
        );

        $scope.$watch(
            'onSelect',
            function(onSelect) {
                NoteManagementController.onSelect = DefaultVariableService.get(
                    onSelect,
                    false
                );
            }.bind(NoteManagementController)
        );

        NoteManagementController.clear = clear;
        function clear() {
            NoteManagementController.reset();
            NoteListService.reset();
        }

        NoteManagementController.onCreateNote = onCreateNote;
        function onCreateNote() {
            NoteManagementController.toggleCreator();
            NoteManagementController.loadNotes();
        }

        NoteManagementController.onCancel = onCancel;
        function onCancel() {
            NoteManagementController.toggleCreator();
        }

        NoteManagementController.onEditNote = onEditNote;
        function onEditNote() {
            NoteManagementController.resetNote();

            NoteManagementController.loadNotes();
        }

        NoteManagementController.loadNotes = loadNotes;
        function loadNotes(options) {
            NoteManagementController.isLoadingNotes = true;

            return NoteListService.loadNotes(
                options
            ).then(
                function(data) {
                    if (data) {
                        NoteManagementController.notes = DefaultVariableService.getArray(
                            data.notes
                        );

                        NoteManagementController.count = DefaultVariableService.getInteger(
                            data.count,
                            0
                        );
                    }

                    return data;
                }
            ).finally(
                function() {
                    NoteManagementController.isLoadingNotes = false;
                }
            );
        }

        NoteManagementController.selectNote = selectNote;
        function selectNote(note) {
            if (NoteManagementController.onSelect) {
                NoteManagementController.onSelect(note);
            } else {
                return NoteManagementController.toggleEditor(note);
            }
        }

        NoteManagementController.setUrl = setUrl;
        function setUrl(action, noteId) {
            RedirectService.setUrl(
                {
                    action: action,
                    noteId: noteId
                }
            );
        }

        NoteManagementController.toggleCreator = toggleCreator;
        function toggleCreator() {
            NoteManagementController.showCreator = !NoteManagementController.showCreator;

            var action = '';
            if (NoteManagementController.showCreator) {
                action = 'create';
            }

            NoteManagementController.setUrl(action);
        }

        NoteManagementController.toggleEditor = toggleEditor;
        function toggleEditor(note) {
            note = DefaultVariableService.getObject(note);

            note.showEditor = DefaultVariableService.toggle(
                note.showEditor,
                false
            );

            var action = '';
            if (note.showEditor && note.id !== 0) {
                NoteManagementController.note = note;

                action = 'edit';
            } else {
                NoteManagementController.resetNote();
            }

            if (NoteManagementController.note.id === 0) {
                NoteManagementController.setUrl(action);
            } else {
                NoteManagementController.setUrl(action, NoteManagementController.note.id);
            }
        }

        NoteManagementController.reset = reset;
        function reset() {
            NoteManagementController.columns = [
                {
                    position: 1,
                    title:    'ID',
                    key:      'id'
                },
                {
                    position: 2,
                    title:    'CATEGORY',
                    key:      'note_category.name'
                },
                {
                    position: 3,
                    title:    'TITLE',
                    key:      'title'
                }
            ];

            NoteManagementController.isLoadingNotes = false;

            NoteManagementController.resetNote();

            NoteManagementController.showCreator = false;

            NoteManagementController.note = DefaultVariableService.getObject();

            NoteManagementController.notes = [];

            NoteManagementController.route();
        }

        NoteManagementController.resetNote = resetNote;
        function resetNote() {
            NoteManagementController.note = DefaultVariableService.getObject();
        }

        NoteManagementController.route = route;
        function route() {
            var action = EnvironmentService.get('action', false);

            if (DefaultVariableService.isString(action)) {
                action = action.toLowerCase();
            }

            if (action === 'create') {
                NoteManagementController.toggleCreator();
            } else if (action === 'edit') {
                var noteId = DefaultVariableService.getInteger(
                    EnvironmentService.get('noteId', 0),
                    0
                );

                if (noteId !== 0) {
                    NoteManagementController.toggleEditor({id: noteId});
                } else {
                    action = false;
                }
            }

            if (!action) {
                NoteManagementController.setUrl();
            }
        }

        NoteManagementController.init = init;
        function init() {
            NoteManagementController.reset();
            NoteManagementController.loadNotes();
        }

        LoginService.register(NoteManagementController);
    }
})();
(function() {
    'use strict';

    angular.module('ionicNotes').directive('ionicNoteManagement', ionicNoteManagement);

    function ionicNoteManagement() {
        return {
            controller:   'NoteManagementController',
            controllerAs: 'ctrl',
            restrict:     'E',
            scope: {
                allowCreate: '=',
                onSelect:    '='
            },
            template:'<div data-ng-if="ctrl.note.id === 0 && !ctrl.showCreator"><div data-ng-if="ctrl.allowCreate"><button class="button button-full accept-button" data-ng-click="ctrl.toggleCreator()">CREATE NOTE</button></div><ionic-data-table columns="ctrl.columns" on-request="ctrl.loadNotes" on-select="ctrl.selectNote"></ionic-data-table></div><ionic-note-edit data-ng-if="ctrl.note.id !== 0" on-cancel="ctrl.toggleEditor" on-edit="ctrl.onEditNote" on-delete="ctrl.deleteNote" note="ctrl.note"></ionic-note-edit><div data-ng-if="ctrl.showCreator"><ionic-note-create on-cancel="ctrl.onCancel" on-create="ctrl.onCreateNote"></ionic-note-create></div>'
        };
    }
})();
(function() {
    'use strict';

    angular.module('ionicNotes').controller(
        'NoteEditController',
        NoteEditController
    );

    NoteEditController.$inject = [
        'AlertService',
        'DefaultVariableService',
        'IonicConfirmationService',
        'LoginService',
        'MomentService',
        'NoteEditService',
        '$scope'
    ];

    function NoteEditController(
        AlertService,
        DefaultVariableService,
        IonicConfirmationService,
        LoginService,
        MomentService,
        NoteEditService,
        $scope
    ) {
        var NoteEditController = this;

        $scope.$watch(
            'onCancel',
            function(onCancel) {
                NoteEditController.onCancel = DefaultVariableService.get(
                    onCancel,
                    false
                );
            }
        );

        $scope.$watch(
            'onDelete',
            function(onDelete) {
                NoteEditController.onDelete = DefaultVariableService.getPromise(
                    onDelete
                );
            }
        );

        $scope.$watch(
            'onEdit',
            function(onEdit) {
                NoteEditController.onEdit = DefaultVariableService.getPromise(
                    onEdit
                );
            }
        );

        $scope.$watch(
            'note',
            function(note) {
                NoteEditController.loadNote(note);
            }
        );

        NoteEditController.cancel = cancel;
        function cancel() {
            if (NoteEditController.onCancel) {
                NoteEditController.onCancel();
            }
        }

        NoteEditController.clear = clear;
        function clear() {
            NoteEditService.reset();
            NoteEditController.reset();
        }

        NoteEditController.forceRefresh = forceRefresh;
        function forceRefresh() {
            NoteEditController.clear();
            NoteEditController.init();
        }

        NoteEditController.deleteNote = deleteNote;
        function deleteNote() {
            NoteEditController.isDeletingNote = true;

            return IonicConfirmationService.createConfirmation(
                'ARE YOU SURE YOU WANT TO DELETE ' + note.title + '?',
                'DELETE',
                'CANCEL'
            ).then(
                function(canDelete) {
                    if (canDelete) {
                        return NoteEditService.deleteNote(
                            NoteEditController.note
                        ).then(
                            function(note) {
                                if (note) {
                                    AlertService.addMessage(
                                        'NOTE DELETED.'
                                    );

                                    NoteEditController.onEdit();

                                    NoteEditController.forceRefresh();
                                }

                                return note;
                            }
                        );
                    }

                    return canDelete;
                }
            ).finally(
                function() {
                    NoteEditController.isDeletingNote = false;
                }
            );
        }

        NoteEditController.editNote = editNote;
        function editNote() {
            NoteEditController.isEditingNote = true;

            return NoteEditService.editNote(
                NoteEditController.note
            ).then(
                function(note) {
                    if (note) {
                        AlertService.addMessage(NoteEditController.onEditMessage);

                        NoteEditController.onEdit(note);

                        NoteEditController.forceRefresh();
                    }

                    return note;
                }
            ).finally(
                function() {
                    NoteEditController.isEditingNote = false;
                }
            );
        }

        NoteEditController.loadNote = loadNote;
        function loadNote(note) {
            NoteEditController.note = note;
        }

        NoteEditController.reset = reset;
        function reset() {
            NoteEditController.editButtonText = DefaultVariableService.getString(
                NoteEditController.editButtonText,
                'EDIT'
            );

            NoteEditController.editingButtonText = DefaultVariableService.getString(
                NoteEditController.editButtonText,
                'EDITING...'
            );

            NoteEditController.isEditingNote = false;

            NoteEditController.isLoadingNote = true;

            NoteEditController.onEditMessage = DefaultVariableService.getString(
                NoteEditController.onEditMessage,
                'NOTE UPDATED.'
            );
        }

        NoteEditController.selectCategory = selectCategory;
        function selectCategory(category) {
            NoteEditController.newNote.note_category = DefaultVariableService.getObject(
                category
            );

            NoteEditController.newNote.note_category_id = NoteEditController.newNote.note_category.id;
        }

        NoteEditController.selectParticipant = selectParticipant;
        function selectParticipant(participant) {
            NoteEditController.newNote.participants = DefaultVariableService.getObject(
                participant
            );

            NoteEditController.newNote.participant_id = NoteEditController.newNote.participant.id;
        }

        NoteEditController.init = init;
        function init() {
            NoteEditController.reset();
        }

        LoginService.register(NoteEditController);
    }
})();
(function() {
    'use strict';

    angular.module('ionicNotes').directive('ionicNoteEdit', ionicNoteEdit);

    function ionicNoteEdit() {
        return {
            bindToController: {
                editButtonText:    '@',
                editingButtonText: '@',
                onEditMessage:     '@'
            },
            controller:   'NoteEditController',
            controllerAs: 'ctrl',
            restrict:     'E',
            scope: {
                onCancel: '=',
                onDelete: '=',
                onEdit:   '=',
                note:     '='
            },
            template:'<ion-list class="list padding-horizontal"><div class="row"><label class="item item-input item-floating-label col-12"><span class="input-label">TITLE</span> <input type="text" placeholder="TITLE" data-ng-model="ctrl.note.title"></label></div><label class="item item-input item-stacked-label row"><span class="input-label">CATEGORY</span><ionic-note-category-filter on-select="ctrl.selectCategory" target="ctrl.note.note_categories"></ionic-note-category-filter></label> <label class="item item-input item-stacked-label row"><span class="input-label">PARTICIPANT</span><ionic-friend-filter on-select="ctrl.selectParticipant" target="ctrl.note.participants"></ionic-friend-filter></label> <label class="item item-input"><textarea data-ng-model="ctrl.note.note" placeholder="MESSAGE">\n            {{ ctrl.note.note }}\n        </textarea></label><div class="row"><button class="button button-full accept-button" data-ng-class="{ \'col-xl-6\': ctrl.onCancel }" data-ng-click="ctrl.editNote()" data-ng-disabled="ctrl.isEditingNote">{{ ctrl.isEditingNote ? \'EDITING...\' : \'EDIT\' }}</button> <button data-ng-if="ctrl.onCancel" class="button button-full decline-button" data-ng-class="{ \'col-xl-6\': ctrl.onCancel }" data-ng-click="ctrl.cancel()" data-ng-disabled="ctrl.isEditingNote">CANCEL</button></div></ion-list>'
        };
    }
})();